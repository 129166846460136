import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ErrorPage = () => {
  return (
    <Layout>
      <Seo title="Página não encontrada" defer={false} />
      <main>
        <title>Not found</title>
        <h1> Page not found</h1>
      </main>
    </Layout>
  )
}

export default ErrorPage
